<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import Home from './pages/Home-page.vue'
import NotFound from './pages/subpages/notFound-page.vue'
import Packages from './pages/packages-page.vue'
import '@/assets/css/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Doctors from './pages/Doctors-page.vue'
import Services from './pages/services-page.vue'
import NursingServices from './pages/nursingServices-page.vue'
import AncillaryServices from './pages/ancillaryServices-page.vue'
import PharmacyServices from './pages/pharmacyServices-page.vue'
import privacyStatementPage from './pages/subpages/privacyStatement-page.vue'
import aboutUsPage from './pages/aboutUs-pages.vue'
import missionVision from './pages/missionVision.vue'
import patientRightsPage from './pages/patientRights-page.vue'

function updateTitle(path) {
  switch (path) {
    case '/':
      document.title = "Home | Manuel J. Santos Hospital";
      break;
    case '/services/medical-services':
    case '/services/nursing-services':
    case '/services/ancillary-services':
    case '/services/pharmacy-services':
      document.title = "Our Services | Manuel J. Santos Hospital";
      break;
    case '/about-us/mission-vision':
    case '/about-us/history':
      document.title = "About Us | Manuel J. Santos Hospital";
      break;
    case '/doctors':
    document.title = "Doctors | Manuel J. Santos Hospital";
    break;
    case '/packages':
    document.title = "Pakages | Manuel J. Santos Hospital";
    break;
    case '/privacy-statement':
      document.title = "Privacy Notice | Manuel J. Santos Hospital";
      break;
    case '/404':
      document.title = "Page Not Found | Manuel J. Santos Hospital";
      break;
    default:
      document.title = "Manuel J. Santos Hospital";
  }
}

export default {
  setup() {
    const routes = {
      '/': Home,
      '/packages': Packages,
      '/doctors': Doctors,
      '/about-us/mission-vision': missionVision,
      '/about-us/history': aboutUsPage,
      '/services/medical-services': Services,
      '/services/nursing-services': NursingServices,
      '/services/ancillary-services': AncillaryServices,
      '/services/pharmacy-services': PharmacyServices,
      '/privacy-statement': privacyStatementPage,
      '/patients-rights-and-resposibilities': patientRightsPage,
      '/404': NotFound // 404 route
    };

    const currentPath = ref(window.location.pathname);

    const navigate = (path) => {
      if (routes[path]) {
        // Exact match route
        window.history.pushState({}, '', path);
        currentPath.value = path;
      } else if (Object.keys(routes).some(route => path.startsWith(route))) {
        // Match if the path starts with any known route (like `/services/nursing-services`)
        window.history.pushState({}, '', path);
        currentPath.value = path;
      } else {
        // Redirect to 404 if no match is found
        window.history.pushState({}, '', '/404');
        currentPath.value = '/404';
      }
      updateTitle(currentPath.value); // Update title after navigation
      window.scrollTo(0, 0);
    };

    const currentView = computed(() => {
      // Find an exact route match or use NotFound
      return routes[currentPath.value] || NotFound;
    });

    const handlePopState = () => {
      const path = window.location.pathname;
      currentPath.value = routes[path] ? path : '/404';
      updateTitle(currentPath.value); // Update title when URL changes with back/forward buttons
    };

    onMounted(() => {
      updateTitle(currentPath.value); // Initial title update
      window.addEventListener('popstate', handlePopState);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('popstate', handlePopState);
    });

    return {
      currentView,
      navigate,
    };
  },
};
</script>

<template>
  <div class="navigation">
    <div class="navbar1">
      <ul>
        <li>
          <a href="/"><img src="./assets/logo.png"></a><a href="/" style="text-decoration: none;"><h4>Manuel J. Santos Hospital</h4></a>
        </li>
        <li class="basicinfo">
          <i class="bi bi-telephone-forward-fill"></i>Contact us <br> 0968-474-2164 | 0915-098-6961  
        </li>
        <li class="basicinfo2">
          <i class="bi-envelope-fill"></i> Message us <br> revenue@mjsantos.com.ph 
        </li>
        <li class="basicinfo3">
          <i class="bi bi-geo-alt-fill"></i> 554 Montilla Blvd., Diego Silang<br>Butuan city
        </li>
      </ul>
    </div>
    <nav class="navbar navbar-expand-lg" data-bs-theme="dark">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" @click.prevent="navigate('/')" style="cursor: pointer;">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" @click.prevent="navigate('/packages')" style="cursor: pointer;">Packages</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" @click.prevent="navigate('/services/medical-services')" style="cursor: pointer;">Our Hospital Services</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" @click.prevent="navigate('/doctors')" style="cursor: pointer;">Our Doctors</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" @click.prevent="navigate('/about-us/history')" style="cursor: pointer;">About Our Hospital</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <component :is="currentView" @navigate="navigate" />

  <footer>
    <div class="footer">
      <div class="footer-head"></div>
      <div class="footer-body">
        <div class="footer-links">
          <h5>Useful links</h5>
          <div class="item"><a class="nav-link" href="">Our Services</a></div>
          <div class="item"><a class="nav-link" href="">Accredited HMOs</a></div>
          <div class="item"><a class="nav-link" href="">Admission Guide</a></div>
          <div class="item"><a class="nav-link" href="">Discharge Guide</a></div>
          <div class="item"><a class="nav-link" href="">Philhealth Guide</a></div>
          <div class="item"><a class="nav-link" href="/patients-rights-and-resposibilities">Patient's Rights & Responsibilities</a></div>
          <div class="item"><a class="nav-link" href="/privacy-statement">Privacy Statement</a></div>
        </div>
        <div class="footer-links">
          <h5>Contact Us</h5>
          <div class="item">554 Montilla Blvd.,Diego Silang Butuan City</div>
          <div class="item">0968-474-2164 | 0915-098-6961</div>
          <div class="item">revenue@mjsantos.com.ph</div>
        </div>
        <div class="footer-links">
          <h5>Follow Us</h5>  
          <div class="item">Follow us & get notified on :</div>
          <div class="item"><a class="socialMedia" href="https://www.facebook.com/ManuelJSantosHospital"><i class="bi bi-facebook"></i></a><a class="socialMedia" href="https://www.instagram.com/manuelj.santoshospital/"><i class="bi bi-instagram"></i></a><a class="socialMedia" href="https://x.com/SantosHospital"><i class="bi bi-twitter-x"></i></a></div>
        </div>
      </div>
      <div class="footer-footer">
        <i class="bi bi-c-circle"></i><p> 2024 Manuel J. Santos Hospital. All Rights Reserved.</p> 
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.navigation{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.bi-telephone-forward-fill{
  margin-right: 10px;
}
.bi-envelope-fill{
  margin-right: 10px;
}
.bi-geo-alt-fill{
  margin-right: 10px;
}

.navbar1{
  height:70px;
  background-color: white;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin-left: 2%;
  }
  li {
    margin-right: 3%;
    float: left;
    display: flex; 
    margin-top: 13px;
    img{
      width:55px;
      height: 60px;
      margin-top: -10px;
    }
    h4{
      margin-left: 8px;
      margin-top: 8px;
      font-weight: 600;
      font-size: larger;
      color: #097e38;
      font-size: 22px;
      font-family: 'University-roman-regular';
    }
  }
}
// -------------------------------------------footer
.footer{
  padding-inline: 5%;
  background-color: rgb(241, 241, 241);
  .footer-body{
    justify-content: center;
    display:flex;
    flex-direction: row;
    font-family: 'Montserrat';
    border-bottom: 1px solid #097e38;
    padding-top:50px;
    padding-bottom: 40px;
    .footer-links{
      text-align: center;
      width: 40%;
      .item{
        font-size: 1rem;
        .socialMedia{
          font-size:x-large;
          margin-left: 10px;
          text-decoration: none;
          color: black;
          &:hover{
            text-decoration: underline;
          }
        }
      }
      h5{
      font-size: 1.7rem;
      font-weight: 600;
      color: #097e38;
      }
      a{
        margin-bottom: 2px;
        &:hover{
          text-decoration: underline;
        }
      }
      
    }
  }
  .footer-footer{
    padding-bottom: 25px;
    i{
      font-size: 12px;
      vertical-align: 2px;
    }
    p{
      display: inline;
      margin-left: 2px;
      font-size: 12px;
      font-family: "Outfit";
    }                                                                                                                                                                                                               
  }
}
// ---------------------------------------navbar
.navbar{
  margin: 0px;
  padding: 0px;
  background-color:#089741;
  .nav-item{
    &:active{
      color:white;
      background-color: #0f6d34; ;
    }
  }
 .nav-link{
  &:hover{
    background-color: #0f6d34;
  }
  .dropdown-toggle {
  color: white !important; /* Ensure the link text is white by default */
}
 }
 .dropdown-menu{
  background-color: #089741;
  .dropdown-item{
    color: white;
    &:hover {
    background-color: white;
    color: #0f6d34; /* Hover color */
  }
  }
 }
   .nav-link{
    padding-top: .8rem;
    color: white;
    height: 2.8rem;
    font-weight: 500;
    font-family: 'Montserrat';
    font-size: .9rem;
   }
}
$primary-color: #193c86;
@media (max-width: 430px) {
  .navbar1{
    .basicinfo{
      margin-left: -1rem;
    }
  }
}
@media (max-width: 504px) {
  .navbar1{
    h4{
      width: 6rem;
      font-size: smaller !important;
    }
    .basicinfo{
      font-size: smaller !important;
    }
    .basicinfo2{
      visibility: hidden !important;
    }
  }
}
@media (max-width: 850px){
  .navigation{

    .basicinfo{
      font-size: smaller !important;
    }
    .basicinfo2{
      visibility: hidden !important;
    }
  }
}
@media (max-width: 1170px){
  .navigation{
    .basicinfo{
      font-size: smaller !important;
    }
    .basicinfo3{
      visibility: hidden !important;

    }
  }
}

@media (max-width: 1121px){
  .footer{
    .footer-head{
    text-align: center;
    justify-content: center;
    }
    .footer-body{
      padding-bottom: 0px;
      padding-top:20px;
      align-items: center;
      flex-direction: column;
      border-bottom: none;
      gap: 20px;
      .footer-links{  
        width: 60%;
        padding-bottom: 20px;
        border-bottom: 1px solid black;
        .footer-links h6{
          text-align: center;
        }
        .nav-link{
          text-align: center;
          display: block;
        }
        .email{
          padding:2px;
        }
        .social-links{
          display: flex; 
          gap: 2px; 
          justify-content: center;
        }
      }
    }
    .footer-footer{
      text-align: center;
    }
  }
}
</style>
