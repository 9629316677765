<template>
    <div id="home" class="home-section ">
      <div id="carouselExampleIndicators" class="carousel slide carousel-dark carousel-fade" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="assets/images/homepage/carousel/image-1.png" class=" image" alt="...">
          </div>
          <div class="carousel-item">
            <img src="assets/images/homepage/carousel/image-2.png" class="image" alt="...">
          </div>                                                                                                                                                                                                                                                                    
          <div class="carousel-item">
            <img src="assets/images/homepage/carousel/image-3.png" class=" image" alt                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ="...">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div> 
      <div id="news-events" class="news-events-section">
      <h1>News & Events</h1>
      <!-- News and Events Cards -->
      <div class="row cards">
        <div class="col-md-3 d-flex justify-content-center" v-for="event in paginatedEvents" :key="event.id">
          <div class="card">
            <img :src="event.image" class="card-img-top" :alt="event.title">
            <div class="card-text">
              <h6>{{ event.title }}</h6>
              <p>{{ event.date }}</p>
              <button type="button"  @click="goToUrl(event.url)" class="btn">View more</button>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <i 
          :class="currentPage === 1 ? 'bi bi-arrow-left-circle' : 'bi bi-arrow-left-circle-fill'" 
          @click="goToPage(currentPage - 1)" 
          :disabled="currentPage === 1">
        </i>

        <span
          v-for="page in totalPages"
          :key="page"
          @click="goToPage(page)"
          :class="{ active: currentPage === page }"
          class="dot"
        ></span>

        <i 
          :class="currentPage === totalPages ? 'bi bi-arrow-right-circle' : 'bi bi-arrow-right-circle-fill'" 
          @click="goToPage(currentPage + 1)" 
          :disabled="currentPage === totalPages">
        </i>
      </div>
    </div>
  </div>
  <div class="home-body mx-auto">
  <h1 class="card-title text-center">About Our Manuel J. Santos Hospital</h1>
  <div class="row justify-content-center">
  <div class="col-md-6 d-flex justify-content-center order-md-2">
    <img src="assets/images/homepage/HOSP.png" class="img-fluid" style="border-radius: 5px;" alt="Hospital Image">
  </div>
  <div class="col-md-5 order-md-1">
    <p class="card-text">
      The Manuel J. Santos Hospital (MJSH), founded in 1933 by Dr. Manuel J. Santos and his wife Amparo Lopez, has been serving the community for over 90 years, growing from a 12-bed facility to a 100-bed, Level 2 hospital. Now part of the Metro Pacific Hospital Group, the largest private hospital group in the Philippines, MJSH is known for its quality patient care and innovation in facilities and services. It also operates a Renal Care Center and is expanding with the conversion of the Prince Hotel into a Medical Arts Building and the establishment of a Primary Care Center in Nasipit.
    </p>
   <a href="/about-us/history"><button class="btn">Read More About Us</button></a>
  </div>
</div>
<div class="coreValues">
  <div class="row justify-content-center">
    <div class="col-md-4">
      <h5>Our Vision</h5>
        <img src="assets/images/homepage/light-bulb.png">
        <p>We are the leading and most valued integrated healthcare provider in Caraga, delivering high-quality, sustainable, compassionate, client-centered care, and is recognized as one of the country’s most trusted healthcare provider.</p>
    </div>
    <div class="col-md-4">
      <h5>Our Mission</h5>
      <img src="assets/images/homepage/objective.png">
      <p>We deliver integrated quality healthcare services that are accessible and sustainable.<br>
        We contribute to nation-building by enabling a healthier Philippines.<br>
        We provide excellent and holistic care with compassion.<br>
        We help develop competent medical and clinical professionals.
      </p>
    </div>
    <div class="col-md-4">
      <h5>Our Core Values</h5>
      <img src="assets/images/homepage/value.png">
      <div class="justify-content-center" style="display: flex;"><h6 class="coreText">Living</h6> <h6 class="coreText">Our</h6> <h6 class="coreText">Values</h6>  <h6 class="coreText">Everyday</h6></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  
  data() {
    return {
      events: [
      { id: 1, title: "National Mental Health Week", date: "October 7-12, 2024", image: "/assets/images/homepage/events/10-7-2024.jpg", url: "https://www.facebook.com/photo?fbid=998719755389268&set=a.552639183330663" },
      { id: 2, title: "Pure Tone Audiometry Test", date: "October 15, 2024", image: "/assets/images/homepage/events/10-15-2024.jpg", url: "https://www.facebook.com/photo/?fbid=1004774781450432&set=a.552639183330663" },
      { id: 3, title: "Access Your Meds with Ease: MedExpress Outpatient Pharmacy", date: "October 17, 2024", image: "/assets/images/homepage/events/10-17-2024.jpg", url: "https://www.facebook.com/photo/?fbid=1006066407987936&set=a.552639183330663" },
      { id: 4, title: "MJSH joins the finalists for the 2024 SVP Clinical Nursing Excellence.", date: "October 18, 2024", image: "/assets/images/homepage/events/10-18-2024.jpg", url: "https://www.facebook.com/photo/?fbid=1007186601209250&set=a.552639183330663" },
      { id: 5, title: "MJSH is proud as Xavier University - Ateneo de Cagayan competes in 2024 BOTN", date: "October 18, 2024", image: "/assets/images/homepage/events/10-18-2024-2.jpg", url: "https://www.facebook.com/photo/?fbid=1007023344558909&set=a.552639183330663" }
      ],
      currentPage: 1,
      baseEventsPerPage: 3,
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    totalPages() {
      // Calculate total number of pages
      return Math.ceil(this.events.length / this.eventsPerPage);
    },
    eventsPerPage() {
      // Adjust eventsPerPage based on screen width
      if (this.screenWidth < 770) return 1; // Small screens
      if (this.screenWidth < 900) return 2; // Medium screens
      return this.baseEventsPerPage; // Default for larger screens
    },
    paginatedEvents() {
      const startIndex = (this.currentPage - 1) * this.eventsPerPage;
      const endIndex = startIndex + this.eventsPerPage;
      return this.events.slice(startIndex, endIndex);
    },
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    goToUrl(url) {
    window.location.href = url;
    },
  goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
  },
  },
  mounted() {
    // Listen for window resize events
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    // Remove the listener when component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },

};
</script>

<style scoped lang="scss">
.btn{
  background-color: #097e38;
  color: white;
  &:hover{
    border: 1px solid #097e38 !important;
    background-color: #097e38 !important; 
    color: white;
  }
}
.coreValues{
width: 100%;
overflow: hidden;
margin-bottom: 5rem;
padding: none;
  .coreText{
    font-family: 'Montserrat';
    font-size: large;
    &::first-letter{
      font-weight: 700;
      font-size: 2em;
      color: #097e38; 
    } 
  }
  text-align: center;
  p{
    font-size: large;
    font-weight: 400;
    font-family: 'Montserrat';
    
  }
  img{
    width: 3rem;
    margin-bottom: 1rem;
  }
  h5{
    text-align: center;
    font-family: 'Outfit';
    font-weight: 600;
    font-size: 30px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #097e38;
  }
}

.home-body{
  overflow: hidden;
  max-width: 100%;
  h1{
    text-align: center;
    font-family: 'Outfit';
    font-weight: 400;
    font-size: 20px;
    margin-top: 5rem;
    margin-bottom: 2rem;
    color: #097e38;
  }
  .card-text{
  font-family: 'Outfit';
  margin-top: 5rem;
  }

}

.cards {
  max-width: 100%; 
  overflow-x: hidden;
  margin: 0 auto;
}
.news-events-section 
{
  padding: none;
  h1 {
  margin-bottom: 40px;
  text-align: center;
  font-family: 'Outfit';
  color: #097e38;
  }
  .card {
  margin-bottom: 1rem;
  overflow: hidden;
  width: 16rem;
  height: 26rem; /* Adjust height as needed */
  }
  .row {
  justify-content: center;
  }
  .card-text {
    border-radius: 0px 3px 3px 0px;
    padding: 10px;
    text-align: left;
    color: black;
    background-color: white;
  }
  .card-text h6{
    font-weight: 400;
    height: 3.2rem;
  }
  .card-text p {
    border-top: 1px ridge black;
    padding-top: 10px;
    text-align: left;
    font-size: small;
  }
}
 

.pagination {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc; /* Default dot color */
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #097e38; /* Active dot color */
}

i.bi {
  font-size: 1.5rem;
  cursor: pointer;
  color: #097e38;
}





.home-section{
  height: 100%;
  margin-top: 6.5rem;
  padding-bottom: 3vh;
  text-align: center;
  background-color: white;
  color: black; 
  padding: none;
  p{
    font-size: smaller;
    margin-bottom: 1rem;
    font-family: 'Outfit';
  }
  h3{ 
    padding-top: 80px;
    font-family: 'Outfit';
    font-size: 20px;

  }
  #carouselExampleIndicators {
    .carousel-inner img {
      width: 100%; 
      height: auto; 
    }
    .carousel-inner{
      .carousel-item{
        margin-bottom: 20px;
      }
    }
  .carousel-control-prev,
  .carousel-control-next {
  display: none;
}
  }
}

@media (max-width: 770px){
  .home-body{
    margin-top: -5rem !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    h1{
      margin-bottom: -0.7rem;
    }
    .card-text{
      margin-top: -0.7rem;
    }
  }
}

</style>