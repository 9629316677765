<template>
    <div class="not-found">
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you're looking for doesn't exist.</p>
      <button @click="goHome">Go to Homepage</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
    setup(_, { emit }) {
      const goHome = () => {
        emit('navigate', '/');
      };
  
      return { goHome };
    },
  };
  </script>
  
  <style scoped>
  .not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    color: #333;
    margin-top: 5rem;
  }
  
  .not-found h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .not-found p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .not-found button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #097e38;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .not-found button:hover {
    background-color: #0056b3;
  }
  </style>