<template>
    <div class="container mt-5">
      <div class="">
        <div class="card-header">
          <h2>Patients Rights</h2>
        </div>
        <div class="card-body">
          <p>
            Here at our hospital, we are fully committed to honoring and protecting your rights. We want you to feel confident and cared for every step of the way.
          </p>
          <h5>1. Quality Medical Care</h5>
          <ul>
            <li>You deserve quality medical care, free of discrimination. Your care team will always prioritize your well-being and follow the highest medical standards.</li>
            <li>We ensure that qualified professionals handle your treatment, making clinical and ethical decisions independently for your best care.</li>
            <li>If you need care from multiple providers, we will coordinate it to maintain continuity and quality in your treatment.</li>
            <li>We are committed to making fair choices in situations with limited resources and to addressing the needs of special populations, such as those with disabilities, pregnant women, and seniors.</li>
            <li>Gender equality and protection against discrimination are core to our approach, and we provide a safe environment, especially for those vulnerable to violence or discrimination.</li>
          </ul>
  
          <h5>2. Freedom to Choose</h5>
          <ul>
            <li>You are free to choose your healthcare provider, switch doctors if needed, and seek a second opinion whenever you wish.</li>
          </ul>
          <h5>3. Self-Determination</h5>
          <ul>
            <li>Your decisions about your health are yours to make. Our role is to give you clear, complete information so that you can make informed choices.</li>
            <li>You may accept or decline any treatment, and we will respect your wishes, including decisions on research participation.</li>
            <li>If you want someone else to help make decisions, particularly in serious cases, we will work with that person on your behalf.</li>
          </ul>
          <h5>4. Unconscious Patients</h5>
          <lu>
            <li>
                If you are ever unable to express your wishes, we’ll do our best to ensure decisions align with your preferences. In emergencies, your health and safety are our priority.
            </li>
          </lu>
          <h5>5. Legal Competency</h5>
          <ul>
            <li>For minors or those legally unable to make decisions independently, we respect the views of both the patient and their representative. We’ll also challenge any decision if it’s not in the patient’s best interest, acting in emergencies if needed.</li>
          </ul>
          <h5>6. Medical Prodedures Against Your Will</h5>
          <ul>
            <li>Procedures without consent are rare and only happen if absolutely necessary, respecting both your rights and the law.</li>
          </ul>
          <h5>7. Information and Understanding</h5>
          <ul>
            <li>You have the right to understand your health status and review your medical records. We’ll provide information in a way you can understand, including financial details and the identities of those caring for you.</li>
          </ul>
          <h5>8. Privacy and Confidentiality</h5>
          <ul>
            <li>Your health information is private. We will only share details if you consent or as required by law. Any data we collect is securely stored and protected.</li>
            <li>Photography in sensitive areas is strictly controlled to protect patient privacy. If needed, you can have someone with you during exams.</li>
          </ul>
          <h5>9. Health Education</h5>
          <ul>
            <li>You deserve education about your health, lifestyle choices and preventive care options. Our team actively participates in health education to help you make informed choices.</li>
          </ul>
          <h5>10. Dignity and Respect</h5>
          <ul>
            <li>
                Your dignity and privacy are central to your care. We aim to relieve your pain and provide compassionate, humane end-of-life care if necessary.
            </li>
          </ul>
          <h5>11. Spiritual and Cultural Needs</h5>
          <ul>
            <li>You can receive or decline spiritual support. If you need religious counseling or specific cultural practices, let us know, and we will work to accommodate these requests in a respectful manner.</li>
          </ul>
          <h5>12. Informed Consent</h5>
          <ul>
            <li>You have the right to decide on your treatment plan, and we’ll help you understand all options available.</li>
          </ul>
          <h5>13. Communication</h5>
          <ul>
            <li>You can communicate with family and loved ones. If needed, an interpreter will be provided, and any limitations on communication will be explained to you.</li>
          </ul>
          <h5>14. Security</h5> 
          <ul>
            <li>Your safety is our priority. Our premises are monitored 24/7, and we have strict security measures to ensure a safe environment.</li>
          </ul>
          <h5>15. Psychosocial Support</h5>
          <ul>
            <li>You are welcome to have family visits, use personal devices, and access the internet to stay connected with others.</li>
          </ul>
          <h5>16. Expressing Grievances</h5>
          <ul>
            <li>Your voice matters. If you have concerns or complaints, you can share them freely, knowing they’ll be addressed without fear of retaliation.</li>
          </ul>
        </div>
        <div class="card-header">
          <h2>Patients Responsibilities</h2>
        </div>
        <div class="card-body">
            <p>As much as we, at Manuel J. Santos Hospital, have responsibilities toward you, you also have certain responsibilities toward us and your healthcare providers. These are important for ensuring your care is effective and your recovery is as smooth as possible:</p>
        <ul>
            <li><strong>Be Honest About You Health: </strong>It's essential that you share your complete medical history and health habits. Being truthful, including about any medications you’re taking—whether prescribed, over-the-counter, or even herbal—can significantly impact your recovery.</li>
            <li><strong>Ask Questions:</strong> If you ever have doubts or misunderstandings about your care, please let us know. If you're uncertain about your treatment or the expected outcomes, don't hesitate to speak up.</li>
            <li><strong>Follow Your Doctor’s Recommendations:</strong> We know that healthcare can be complex, but it's important that you follow the treatment plan set by your physician. If something concerns you or if you’re dissatisfied, please talk to your doctor about it.</li>
            <li><strong>Keep Us Updated on Your Health:</strong> If your condition changes, we need to know so we can adjust your care accordingly.</li>
            <li><strong>Be Considerate:</strong> Please be mindful of others around you, including patients, hospital staff, and the hospital property. Following our rules and policies helps create a peaceful environment for everyone.</li>
            <li><strong>Take Responsibility for Your Actions:</strong> If you refuse treatment or choose not to follow your doctor’s advice, understand that this can impact your recovery. We respect your decisions, but it’s important to be aware of the consequences.</li>
            <li><strong>Provide Accurate Insurance Information:</strong> To ensure proper billing, please provide us with accurate information about your medical insurance coverage.</li>
            <li><strong>Be Truthful and Open with Your Healthcare Team:</strong> Share any concerns with your physicians and healthcare providers, and be honest about your medical history, including past illnesses, medications, hospitalizations, and family medical history.</li>
            <li><strong>Clarify Anything You Don’t Understand:</strong> If you're unsure about your health status or treatment plan, ask for more details. We want to ensure you fully understand what’s going on with your care.</li>
            <li><strong>Cooperate with Your Treatment Plan:</strong> Once you and your doctor have agreed on a treatment plan, it's crucial that you follow through and keep your appointments. Let your doctor know if you are unable to follow any part of the plan or if you want to revisit your treatment options.</li>
            <li><strong>Meet Your Financial Obligations:</strong> Be aware of the costs of your healthcare and ensure you fulfill your financial responsibilities. If you're facing difficulties, talk to us—we can discuss your options.</li>
            <li><strong>Discuss End-of-Life Decisions:</strong> We encourage you to talk with your doctor about your wishes for end-of-life care. You may want to consider writing an advance directive.</li>
            <li><strong>Commit to Health Maintenance:</strong> A healthy lifestyle can prevent illness. Take responsibility for your health and well-being whenever possible.</li>
            <li><strong>Respect Others' Health:</strong> Your behavior affects those around you, so please avoid actions that could harm others. For example, if you have a contagious illness, we ask that you take appropriate precautions to prevent transmission.</li>
            <li><strong>Participate in Medical Education:</strong> Your participation in medical education helps improve healthcare for everyone. We encourage you to accept care from medical students or trainees, as long as it’s under appropriate supervision. Of course, you are free to refuse care from any member of the healthcare team.</li>
            <li><strong>Avoid Fraudulent Practices:</strong>Please refrain from engaging in or supporting fraudulent healthcare practices. If you suspect illegal or unethical behavior by any of our providers, let us know—we want to ensure ethical care for all.</li> 
            <li><strong>Consider Organ Donation:</strong>If you are open to organ donation, please discuss it with your physician. If you decide to become a donor, we’ll help make the necessary arrangements.</li> 
            <li><strong>Know Your Rights and Responsibilities:</strong>When you're admitted, we’ll go over your rights and responsibilities, which are in line with Philippine laws. This includes your informed consent, which you’ll sign to confirm that you understand the treatment and care you’ll receive.</li> 
        </ul>
        </div>
      </div>
    </div>
  </template>
  
  <style scoped>
  .container {
    .card-body{
      font-family: "Outfit"; 
    }
    .card-header{
      font-weight: 700;
      color: #097e09;
      font-family: "Outfit"; 
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
    padding-top: 6rem;
    max-width: 800px;
    padding-bottom: 5rem;
    border-radius: 0px !important;
  }
  h2, h3, h5 {
    font-weight: 500;
  }
  </style>