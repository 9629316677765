<template>
    <div class="container mt-5">
      <div class="">
        <div class="card-header">
          <h2>Privacy Notice</h2>
        </div>
        <h4>Protecting Your Personal Information at Manuel J. Santos Hospital</h4>
        <div class="card-body">
          <p>
            This Privacy Notice explains how Manuel J. Santos Hospital (we) collects, uses, and safeguards any information you may provide when you visit our site. We value your privacy and strive to protect any personal information shared with us.
          </p>
          <h4>1. Types of Information We Collect</h4>
          <p>
            We may collect minimal information primarily to improve our content and engage with our visitors. This may include:
          </p>
          <ul>
            <li><strong>Contact Information:</strong> If you subscribe to our newsletter, comment on posts, or reach out to us directly, we may collect your email address or name.</li>
            <li><strong>Site Usage Data:</strong> To enhance your experience, we may collect non-personally identifiable information like browser type, pages visited, and time spent on the site.</li>
          </ul>
  
          <h4>2. How We Use Your Information</h4>
          <p>
            We use any collected information solely to provide content, respond to inquiries, and enhance our offerings. Specifically, we may use your information to:
          </p>
          <ul>
            <li><strong>Communicate:</strong> Send updates, respond to comments, or notify you of new posts.</li>
            <li><strong>Analyze:</strong> Improve the website’s performance, content relevance, and user experience.</li>
          </ul>
  
          <h4>3. Third-Party Sharing</h4>
          <p>
            We do not sell or share personal information with third parties except as required by law. In some cases, we may use third-party tools (such as analytics providers) to help us understand site usage, but these providers are bound by strict confidentiality obligations.
          </p>
  
          <h4>4. Data Security</h4>
          <p>
            We implement basic security practices to protect your data from unauthorized access. However, we minimize the amount of personal data collected and stored.
          </p>
  
          <h4>5. Your Rights</h4>
          <p>
            You may opt out of any communication or request the removal of your personal data from our records by contacting us.
          </p>
  
          <p class="mt-3">
            For any questions about this Privacy Notice, please contact our Data Protection Officer at
            <a style=" text-decoration: underline; color: #097e09;">dpo@mjsantos.com.ph</a>
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyNotice'
  };
  </script>
  
  <style scoped>
  .container {
    .card-body{
      font-family: "Outfit"; 
    }
    .card-header{
      font-weight: 700;
      color: #097e09;
      font-family: "Outfit"; 
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
    padding-top: 6rem;
    max-width: 800px;
    padding-bottom: 5rem;
    border-radius: 0px !important;
  }
  h2, h3, h4 {
    font-weight: 500;
  }
  </style>