<template>
  <div class="services-container">
      <ul class="nav flex-column list-group list-group-flush">
      <h5>Our Hospital Services</h5>
      <li class="nav-item list-group-item">
        <a 
          class="nav-link" 
          :class="{ active: isActive('/services/medical-services') }" 
          href="/services/medical-services"
        >Medical Services</a>
      </li>
      <li class="nav-item list-group-item">
        <a 
          class="nav-link" 
          :class="{ active: isActive('/services/nursing-services') }" 
          href="services/nursing-services"
        >Nursing Services</a>
      </li>
      <li class="nav-item list-group-item">
        <a 
          class="nav-link" 
          :class="{ active: isActive('/services/ancillary-services') }" 
          href="/services/ancillary-services"
        >Ancillary Services</a>
      </li>
      <li class="nav-item list-group-item">
        <a 
          class="nav-link" 
          :class="{ active: isActive('/services/pharmacy-services') }" 
          href="/services/pharmacy-services"
        >Pharmacy Services</a>
      </li>
    </ul>
    <div class="service-content">
      <div class="row cards ">
      <div class="col-md-4" v-for="service in services" :key="service.id">
        <a href="/services/nursing-services">
        <div class="card servicescard">
          <!-- <img :src="doctor.profilePicture" class="card-img-top" :alt="doctor.firstName + ' ' + doctor.lastName"> -->
          <div class="servicescard-text">
            <h6>{{ service.section }}, </h6>
          </div>
        </div>
      </a>
    </div>
    </div>
</div>
</div>
</template>
<script>
export default {
  data() {
    return {
        services: [
          {
            id: 1,
            section: "Inpatient Services",
          },
          {
            id: 2,
            section: "Emergency Room & Services",
          },
          {
            id: 3,
            section: "Delivery Room",
          }, 
          {
            id: 4,
            section: "MCU / NICU",

          },
          {
            id: 5,
            section: "Operating Room",
          },
          {
            id: 6,
            section: "Intensive Care Unit",

          },
          {
            id: 7,
            section: "Hemodialysis",

          },
          {
            id: 8,
            section: "Oncology",
          },
        ],
    }
  },   
  methods: {
    isActive(path) {
      return window.location.pathname === path;
    },
  },
}

</script>

<style scoped lang="scss">
.service-content{
  align-content: center;
  width: 55rem;
  margin: 1.5rem;
  margin-left: 3rem;
  margin-right: 3rem;
  .card{
    width: 18rem;
    height: 12rem;
    margin-bottom: .8rem;
    &:hover{
      .servicescard-text{
        h6{
          color: #fff;
          filter: brightness(1.5);
          font-weight: 700;
          padding-right: 1rem;
        }
      }
    }
  }
  .servicescard-text{
    border-radius: 0px 3px 3px 0px;
    padding: 5px 10px;
    text-align: center;
    color: white;
    background-color: #097e38;
    position: absolute;
    padding: -10px;
    bottom: 15px;
    left: 0px;
    h6{
      margin: 0; 
      font-family: 'Outfit';
    }
  }
}
.services-container {
  font-family: 'montserrat';
  display: flex;
  margin-top: 8rem;
}
.nav {
  align-items: center;
  width: 15rem;
  h5{
    font-weight: 600;
    color: #097e38;
  }
}
.nav-link {
  color: black;
}
.nav-link:hover,
.nav-link.active {
  color: #097e38 !important;
  font-weight: 600;
}
</style>
