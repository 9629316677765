<template>
  <div id="doctors" class="doctors-section">
  <h1>Our Doctors</h1>

  <!-- Search Bar with Dropdowns -->
  <div class="search-bar">
    <input v-model="searchQuery" type="text" placeholder="Search by name" />
    <!-- Dropdown for Specialization -->
    <select v-model="selectedSpecialization">
      <option value="">All Specializations</option>
      <option v-for="spec in uniqueSpecializations" :key="spec" :value="spec">{{ spec }}</option>
    </select>
    <!-- Dropdown for HMO -->
    <select v-model="selectedHMO">
      <option value="">All HMOs</option>
      <option v-for="hmo in uniqueHmos" :key="hmo" :value="hmo">{{ hmo }}</option>
    </select>

    <button class="searchBtn" @click="searchDoctors">Search</button>
  </div>

  <!-- Doctors Cards -->
  <div class="row cards">
    <div class="col-md-3" v-for="doctor in paginatedDoctors" :key="doctor.id">
      <div class="card doctorscard">
        <img :src="doctor.profilePicture" class="card-img-top" :alt="doctor.firstName + ' ' + doctor.lastName">
        <button 
          type="button" 
          class="btn viewmorebtn btn-outline-success" 
          data-bs-toggle="modal" 
          data-bs-target="#myModal"
          @click="showDoctorDetails(doctor)">View more</button>
        <div class="doctorscard-text">
          <h6>{{ doctor.lastName }}, {{ doctor.firstName }}</h6>
          <p>{{ doctor.specialization }}</p>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog custom-modal-width">
        <div class="row modal-body gap-3">
          <div class="col">
            <img :src="selectedDoctor.profilePicture" class="" :alt="selectedDoctor.firstName + ' ' + selectedDoctor.lastName">
          </div>
          <div class="col">
            <div class="">
              <h5 class="">{{ selectedDoctor.firstName }} {{ selectedDoctor.lastName }}</h5>
              <p class="">{{ selectedDoctor.specialization }}</p>
              <p class=""><medium class="text-body-secondary">Clinic: {{ selectedDoctor.clinic }}</medium></p>
              <p class=""><medium class="text-body-secondary">Contact: {{ selectedDoctor.mobileNo }}</medium></p>
              <p class=""><medium class="text-body-secondary">Schedule: {{ selectedDoctor.schedule }}</medium></p>
              <p class="" style="height: 6rem;"><medium class="text-body-secondary">HMO: {{ selectedDoctor.hmo_names }}</medium></p>
            </div>
          </div>
          <div class="modal-button">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Pagination -->
  <div class="pagination">
    <i 
      :class="currentPage === 1 ? 'bi bi-arrow-left-circle' : 'bi bi-arrow-left-circle-fill'" 
      @click="goToPage(currentPage - 1)" 
      :disabled="currentPage === 1">
    </i>

    <span
      v-for="page in totalPages"
      :key="page"
      @click="goToPage(page)"
      :class="{ active: currentPage === page }"
      class="dot"
    ></span>

    <i 
      :class="currentPage === totalPages ? 'bi bi-arrow-right-circle' : 'bi bi-arrow-right-circle-fill'" 
      @click="goToPage(currentPage + 1)" 
      :disabled="currentPage === totalPages">
    </i>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      doctors: [
  {
    id: 1,
    firstName: "Armando",
    lastName: "Aala",
    clinic: "306",
    schedule: "",
    mobileNo: "",
    profilePicture: "/assets/images/doctors/AALA, ARMANDO.jpeg",
    specialization: "Ear, Nose and Throat (ENT)",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, COCOLIFE",
  },
  {
    id: 2,
    firstName: "Maria Clara",
    lastName: "Aala",
    clinic: "306",
    schedule: "Mon, Wed, Fri 2pm - 4pm",
    mobileNo: "0921-3982-655",
    profilePicture: "/assets/images/doctors/AALA, MARIA CLARA.jpeg",
    specialization: "Pediatrics",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 3,
    firstName: "Ferdinand",
    lastName: "Amante",
    clinic: "304",
    schedule: "Mon & Thu 10am - 2pm",
    mobileNo: "0910-7371-491",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Orthopaedic Surgery",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH",
  },
  {
    id: 4,
    firstName: "Jereme",
    lastName: "Atupan",
    clinic: "401",
    schedule: "Tue & Thu 10am - 1pm",
    mobileNo: "0946-0625-054",
    profilePicture: "/assets/images/doctors/ATUPAN, JEREME.jpeg",
    specialization: "Orthopaedic Surgery",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 5,
    firstName: "Archimedes",
    lastName: "Brodith",
    clinic: "411",
    schedule: "Thu & Fri 3pm - 6pm",
    mobileNo: "0921-7166-688",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Cardiology",
    hmo_names: "PHILCARE",
  },
  {
    id: 6,
    firstName: "May Glor",
    lastName: "Buñol",
    clinic: "506",
    schedule: "Mon, Wed, Fri 1pm - 5pm",
    mobileNo: "0910-5166-386",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Infectious Disease",
    hmo_names: "",
  },
  {
    id: 7,
    firstName: "Roxanne",
    lastName: "Butal",
    clinic: "403",
    schedule: "STRICTLY BY APPOINTMENT",
    mobileNo: "0929-8575-729",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Gastroenterology",
    hmo_names: "",
  },
  {
    id: 8,
    firstName: "Nora",
    lastName: "Calamba",
    clinic: "509",
    schedule: "Tue, Fri, Sat 9am - 12pm",
    mobileNo: "0945-4337-491",
    profilePicture: "/assets/images/doctors/CALAMBA, NORA.jpeg",
    specialization: "Obstetrics/Gynecology",
    hmo_names: "COCOLIFE",
  },
  {
    id: 9,
    firstName: "Bartolome",
    lastName: "Caparas",
    clinic: "511",
    schedule: "Mon & Wed 12pm - 3pm",
    mobileNo: "0915-2699-258",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Orthopaedic Surgery",
    hmo_names: "",
  },
  {
    id: 10,
    firstName: "Maricris",
    lastName: "Caparas",
    clinic: "511",
    schedule: "Tue & Thu 12pm - 4pm",
    mobileNo: "0910-1055-637",
    profilePicture: "/assets/images/doctors/GARCIA-CAPARAS, MARICRIS.jpeg",
    specialization: "Nephrology",
    hmo_names: "",
  },
  {
    id: 11,
    firstName: "Bernardo",
    lastName: "Conde",
    clinic: "303",
    schedule: "STRICTLY BY APPOINTMENT",
    mobileNo: "0963-0948-483",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Psychiatry",
    hmo_names: "",
  },
  {
    id: 12,
    firstName: "Bren",
    lastName: "Dagoc",
    clinic: "504",
    schedule: "",
    mobileNo: "",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Adult Infectious Disease & Tropical Medicine",
    hmo_names: "",
  },
  {
    id: 13,
    firstName: "Delfin",
    lastName: "Dayan",
    clinic: "310",
    schedule: "Mon, Wed, Fri 11am - 4pm",
    mobileNo: "0995-8134-398",
    profilePicture: "/assets/images/doctors/DAYAN, DELFIN.jpeg",
    specialization: "Cardiology",
    hmo_names: "",
  },
  {
    id: 14,
    firstName: "Marie Antonette",
    lastName: "Dellosa",
    clinic: "304",
    schedule: "Wed (by Appointment)",
    mobileNo: "0917-117-3956",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Neurology",
    hmo_names: "",
  },
  {
    id: 15,
    firstName: "Nina Joy",
    lastName: "Dimaano",
    clinic: "308",
    schedule: "Mon - Sat 9am - 12pm (by Appointment)",
    mobileNo: "0945-4507-496",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Obstetrics/Gynecology",
    hmo_names: "",
  },
  {
    id: 16,
    firstName: "Dean Angelo",
    lastName: "Dimaano",
    clinic: "301",
    schedule: "Mon - Sat 9am - 5pm",
    mobileNo: "0945-4507-496",
    profilePicture: "/assets/images/doctors/DIMAANO, DEAN ANGELO.jpeg",
    specialization: "Newborn Medicine",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH",
  },
  {
    id: 17,
    firstName: "Rolando",
    lastName: "Dueñas",
    clinic: "506",
    schedule: "Mon - Sat 10am - 12pm",
    mobileNo: "0963-9753-990",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Gastroenterology",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 18,
    firstName: "Diane Maurise",
    lastName: "Flores",
    clinic: "402",
    schedule: "Mon - Sat 11am - 2pm",
    mobileNo: "0994-9931-975",
    profilePicture: "/assets/images/doctors/FLORES, DIANE MAURISE.jpeg",
    specialization: "Obstetrics/Gynecology",
    hmo_names: "MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH",
  },
  {
    id: 19,
    firstName: "Derrick Bert",
    lastName: "Fuentes",
    clinic: "511",
    schedule: "Mon, Tue, Fri, Sat 10am - 1pm",
    mobileNo: "0910-105-5637 / 0915-169-9258",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Ear, Nose and Throat (ENT)",
    hmo_names: "",
  },
  {
    id: 20,
    firstName: "Roejane",
    lastName: "Gonzaga",
    clinic: "510",
    schedule: "Mon - Sat 10am - 4pm (by Appointment)",
    mobileNo: "0945-5177-585 / 0910-467-9276",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Dentistry",
    hmo_names: "",
  },
  {
    id: 21,
    firstName: "Rene Antonio",
    lastName: "Gonzalez",
    clinic: "302",
    schedule: "Mon, Tue, Thu, Fri 9am - 12pm | Wed & Sat 10am - 12pm",
    mobileNo: "0961-8122-895",
    profilePicture: "/assets/images/doctors/GONZALES, RENATO.jpeg",
    specialization: "Cardiology",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 22,
    firstName: "Annalisa",
    lastName: "Gonzalez",
    clinic: "507",
    schedule: "Tue & Thu 10am - 5pm | Sat 10am - 1pm",
    mobileNo: "0908-8766-143",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Cardiology",
    hmo_names: "",
  },
  {
    id: 23,
    firstName: "Lutgardo",
    lastName: "Gonzalez",
    clinic: "507",
    schedule: "Mon, Wed, Fri 10am - 5pm",
    mobileNo: "0938-6568-285",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Pulmonology",
    hmo_names: "",
  },
  {
    id: 24,
    firstName: "Sylvia",
    lastName: "Hangos",
    clinic: "405",
    schedule: "Thu 11am - 6pm",
    mobileNo: "0985-9915-369 / 0994-9478-908",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Adult Cardiology",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 25,
    firstName: "Marlowe",
    lastName: "Indo",
    clinic: "503",
    schedule: "Mon, Tue, Thu, Fri 9am - 12pm",
    mobileNo: "0910-243-0051",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Neurology",
    hmo_names: "MAXICARE, MEDICARD PHILIPPINES, COCOLIFE",
  },
  {
    id: 26,
    firstName: "Joanna",
    lastName: "Java",
    clinic: "303",
    schedule: "Mon, Tue, Wed 10am - 1pm",
    mobileNo: "0930-3268-720",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Cardiology",
    hmo_names: "",
  },
  {
    id: 27,
    firstName: "Zandra",
    lastName: "Joyohoy",
    clinic: "510",
    schedule: "Tue, Fri 1pm - 5pm (by Appointment)",
    mobileNo: "0945-5177-585",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Dentistry",
    hmo_names: "",
  },
  {
    id: 28,
    firstName: "Ma. Theresa",
    lastName: "Layese",
    clinic: "409",
    schedule: "",
    mobileNo: "",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Cardiology",
    hmo_names: "",
  },
  {
    id: 29,
    firstName: "Deanne Rae",
    lastName: "Libarios",
    clinic: "508",
    schedule: "Mon (by Appointment)",
    mobileNo: "0907-3718-125",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Nephrology",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES",
  },
  {
    id: 30,
    firstName: "Charlene",
    lastName: "Lim",
    clinic: "403",
    schedule: "Tue & Thu 10am - 1pm (by Appointment)",
    mobileNo: "0961-9181-881",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Oncology",
    hmo_names: "MAXICARE, MEDICARD PHILIPPINES",
  },
  {
    id: 31,
    firstName: "Romeo",
    lastName: "Lozano",
    clinic: "305",
    schedule: "",
    mobileNo: "",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Ear, Nose and Throat (ENT)",
    hmo_names: "MAXICARE, MEDICARD PHILIPPINES, COCOLIFE",
  },
  {
    id: 32,
    firstName: "Geraldine",
    lastName: "Malbas",
    clinic: "305",
    schedule: "Mon - Sat 2pm - 5pm",
    mobileNo: "0933-8188-122",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Obstetrics/Gynecology",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 33,
    firstName: "Gabriel",
    lastName: "Malbas",
    clinic: "305",
    schedule: "Tue, Fri, Sat 12pm - 2pm",
    mobileNo: "0933-8588-291",
    profilePicture: "/assets/images/doctors/MALBAS, GABRIEL.jpeg",
    specialization: "General Surgery",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 34,
    firstName: "Marchan",
    lastName: "Olib",
    clinic: "509",
    schedule: "Mon, Wed, Thu 9am - 5pm",
    mobileNo: "0927-8590-951",
    profilePicture: "/assets/images/doctors/OLIB-MARCHAN, ETHEL JOANNE.jpeg",
    specialization: "Pediatrics",
    hmo_names: "MAXICARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 35,
    firstName: "Antonio Jr.",
    lastName: "Montalban",
    clinic: "401",
    schedule: "Tue & Thu 2pm - 4pm",
    mobileNo: "0909-6016-286",
    profilePicture: "/assets/images/doctors/MONTALBAN, ANTONIO.jpeg",
    specialization: "Orthopaedic Surgery",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 36,
    firstName: "Antonette",
    lastName: "Montalban",
    clinic: "401",
    schedule: "Tue & Thu 2pm - 4pm",
    mobileNo: "0909-6016-286",
    profilePicture: "/assets/images/doctors/MONTALBAN, ANTONETTE.jpeg",
    specialization: "Neurosurgery",
    hmo_names: "LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 37,
    firstName: "Renato",
    lastName: "Muñez",
    clinic: "307",
    schedule: "Mon - Sat 9am - 5pm",
    mobileNo: "0910-4266-433",
    profilePicture: "/assets/images/doctors/MUÑEZ, RENATO.jpeg",
    specialization: "General Surgery",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 38,
    firstName: "Daisy",
    lastName: "Nakila",
    clinic: "303",
    schedule: "Mon - Sat 2pm - 5pm",
    mobileNo: "0907-7854-791",
    profilePicture: "/assets/images/doctors/NAKILA, DAISY.jpeg",
    specialization: "General Internal Medicine",
    hmo_names: "MEDICARD PHILIPPINES, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 39,
    firstName: "Virgillo",
    lastName: "Nery",
    clinic: "304",
    schedule: "Mon & Tue 3pm - 5pm | Wed 9am - 10am",
    mobileNo: "0920-9215-669",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "General Surgery",
    hmo_names: "PHILCARE, MEDICARD PHILIPPINES",
  },
  {
    id: 40,
    firstName: "Maureen",
    lastName: "Oraiz",
    clinic: "408",
    schedule: "Wed & Fri 9am - 12pm",
    mobileNo: "0968-8553-930",
    profilePicture: "/assets/images/doctors/ORAIZ-TAN, MAUREEN.jpeg",
    specialization: "Pulmonology",
    hmo_names: "MAXICARE",
  },
  {
    id: 41,
    firstName: "Srilanski",
    lastName: "Osigan",
    clinic: "406",
    schedule: "Mon - Fri 8am - 12pm",
    mobileNo: "0960-2680-313",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Minimally Invasive Surgery",
    hmo_names: "MAXICARE, EASTWEST",
  },
  {
    id: 42,
    firstName: "Ester Lucille",
    lastName: "Pariñas",
    clinic: "410",
    schedule: "Mon - Fri 3pm - 5pm",
    mobileNo: "0905-8724-777",
    profilePicture: "/assets/images/doctors/PARIÑAS, LUCILLE.jpeg",
    specialization: "Pulmonology",
    hmo_names: "",
  },
  {
    id: 43,
    firstName: "Alice",
    lastName: "Pepito",
    clinic: "504",
    schedule: "Mon - Fri 3pm - 5pm",
    mobileNo: "0927-7212-655",
    profilePicture: "/assets/images/doctors/PEPITO, ALICE.jpeg",
    specialization: "General Surgery",
    hmo_names: "",
  },
  {
    id: 44,
    firstName: "Rose Mary",
    lastName: "Ponce",
    clinic: "410",
    schedule: "Mon, Tue, Thu, Fri 9am - 12pm",
    mobileNo: "0948-1619-629",
    profilePicture: "/assets/images/doctors/PONCE, ROSE MARY.jpeg",
    specialization: "Diabetology",
    hmo_names: "MAXICARE, PHILCARE, LIFE AND HEALTH",
  },
  {
    id: 45,
    firstName: "Sharon",
    lastName: "Rafil",
    clinic: "408",
    schedule: "Mon - Fri 2pm - 5pm",
    mobileNo: "0905-3005-003",
    profilePicture: "/assets/images/doctors/RAFIL, SHARON.jpeg",
    specialization: "Pediatrics",
    hmo_names: "MAXICARE, PHILCARE, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 46,
    firstName: "Rey Anthony",
    lastName: "Robles",
    clinic: "305",
    schedule: "Mon - Fri 2pm - 5pm",
    mobileNo: "0995-9017-364",
    profilePicture: "/assets/images/doctors/ROBLES, REY ANTHONY.jpeg",
    specialization: "General Surgery",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 47,
    firstName: "Rose Hycinth",
    lastName: "Salise",
    clinic: "502",
    schedule: "Mon, Tue, Thu, Fri 1pm - 3pm",
    mobileNo: "0933-1424-955",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Pulmonology",
    hmo_names: "",
  },
  {
    id: 48,
    firstName: "Afzelia Theresa",
    lastName: "Sanchez",
    clinic: "503",
    schedule: "Mon - Fri 11am - 4pm | Sat 11am - 1pm",
    mobileNo: "0917-7033-656",
    profilePicture: "/assets/images/doctors/sanchez.jpeg",
    specialization: "Ophthalmology",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 49,
    firstName: "Janet",
    lastName: "Santiago",
    clinic: "508",
    schedule: "Fri 10am - 5pm",
    mobileNo: "0915-014-9208",
    profilePicture: "/assets/images/doctors/SANTIAGO, JANET.jpeg",
    specialization: "IM- Neurology",
    hmo_names: "",
  },
  {
    id: 50,
    firstName: "Sheryl",
    lastName: "Taperla",
    clinic: "508",
    schedule: "Mon - Fri 9am - 12pm",
    mobileNo: "0912-2698-685",
    profilePicture: "/assets/images/doctors/TAPERLA, SHERYL.jpeg",
    specialization: "Internal Medicine",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, LIFE AND HEALTH",
  },
  {
    id: 51,
    firstName: "Edecio",
    lastName: "Urag",
    clinic: "504",
    schedule: "Mon, Wed, Fri 11am - 1pm | Tue, Thu, Sat 9am - 12pm",
    mobileNo: "0995-7385-743",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "General Surgery",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 52,
    firstName: "Renato Jose",
    lastName: "Villanueva",
    clinic: "303",
    schedule: "Mon, Wed 1pm - 3pm | Thu, Fri 10am - 3pm",
    mobileNo: "0963-0948-483",
    profilePicture: "/assets/images/doctors/VILLANUEVA, RENATO.jpeg",
    specialization: "Rehabilitation Medicine",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 53,
    firstName: "Marilou",
    lastName: "Villanueva",
    clinic: "311",
    schedule: "Mon - Sat 10am - 2pm",
    mobileNo: "0905-9711-998",
    profilePicture: "/assets/images/doctors/VILLANUEVA, MARILOU.jpeg",
    specialization: "Pediatrics",
    hmo_names: "PHILCARE, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 54,
    firstName: "Alvin Ray",
    lastName: "Yu",
    clinic: "407",
    schedule: "Mon - Fri 9am - 5pm | Sat 9am - 12pm",
    mobileNo: "0956-1852-515",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Orthopaedic Surgery",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH",
  },
  {
    id: 55,
    firstName: "Odilio",
    lastName: "Yu",
    clinic: "407",
    schedule: "Mon & Wed 2pm - 5pm",
    mobileNo: "0920-9084-317",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Nephrology",
    hmo_names: "MAXICARE, PHILCARE, MEDICARD PHILIPPINES, EASTWEST, LIFE AND HEALTH, COCOLIFE",
  },
  {
    id: 56,
    firstName: "Sebastian",
    lastName: "Tristan",
    clinic: "304",
    schedule: "Tue 9am - 11am | Fri 1pm-3pm | Sat 10am -12pm",
    mobileNo: "0910-7371-491",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Orthopaedic Surgery",
    hmo_names: "",
  },
  {
    id: 57,
    firstName: "Sison",
    lastName: "Dimaano",
    clinic: "309",
    schedule: "Mon - Sat 9am - 5pm",
    mobileNo: "0927-3791-075",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Oncologist",
    hmo_names: "",
  },
  {
    id: 58,
    firstName: "Blake",
    lastName: "Ang",
    clinic: "504",
    schedule: "Tue & Thu 3pm - 5pm",
    mobileNo: "0970-7538-615",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "Pulmonology",
    hmo_names: "MAXICARE, MEDICARD PHILIPPINES",
  },
  {
    id: 59,
    firstName: "Miguel",
    lastName: "Dimaano",
    clinic: "309",
    schedule: "Mon - Sat 9am - 5pm",
    mobileNo: "0927-3791-075",
    profilePicture: "/assets/images/doctors/defaultmale.png",
    specialization: "General Surgery",
    hmo_names: "",
  },
  {
    id: 60,
    firstName: "Roanne",
    lastName: "Yu",
    clinic: "405",
    schedule: "Mon 2pm - 5pm | Wed 9am - 12pm",
    mobileNo: "0927-3791-455",
    profilePicture: "/assets/images/doctors/defaultfemale.png",
    specialization: "Nephrology",
    hmo_names: "",
  },
],
      searchQuery: "",
      selectedSpecialization: "",
      selectedHMO: "",
      currentPage: 1,
      eventsPerPage: 8,
      selectedDoctor: {},
    };
  },
  computed: {
    filteredDoctors() {
      return this.doctors.filter((doctor) => {
        const matchesName = 
          doctor.firstName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          doctor.lastName.toLowerCase().includes(this.searchQuery.toLowerCase());

        const matchesSpecialization = 
          this.selectedSpecialization === "" || 
          doctor.specialization === this.selectedSpecialization;

        const matchesHMO = 
          this.selectedHMO === "" || 
          doctor.hmo_names.split(", ").includes(this.selectedHMO);

        return matchesName && matchesSpecialization && matchesHMO;
      });
    },
    totalPages() {
      return Math.ceil(this.filteredDoctors.length / this.eventsPerPage);
    },
    paginatedDoctors() {
      const startIndex = (this.currentPage - 1) * this.eventsPerPage;
      const endIndex = startIndex + this.eventsPerPage;
      return this.filteredDoctors.slice(startIndex, endIndex);
    },
    uniqueSpecializations() {
      return [...new Set(this.doctors.map((doc) => doc.specialization))];
    },
    uniqueHmos() {
      return [...new Set(this.doctors.flatMap((doc) => doc.hmo_names.split(", ")))].filter(Boolean);
    },
  },
  methods: {
    showDoctorDetails(doctor) {
      this.selectedDoctor = doctor;
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    searchDoctors() {
      this.currentPage = 1; // Reset to the first page on search
    },
  },
};
</script>

<style scoped>
.modal{
  .custom-modal-width{
    max-width: 45%;
  }
  .col{
    padding: 0px !important;
    margin: 0px !important;
  }
  img{
    width: 18rem;
    height: 18rem;
  }
  .modal-body{
    background-color: white;
    
    .modal-button{
      align-items: right;
      justify-content: right;
      text-align: right;
    }
  }
}

.viewmorebtn {
  position: absolute;
  top: 50%;  /* 50% from the top */
  left: 50%;  /* 50% from the left */
  transform: translate(-50%, -50%); 
  color: white;
  font-family: 'Cairo';
  visibility: hidden;
}
.doctors-section {
  display: flex;
  flex-direction: column;
  margin-top: 6.5rem;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 7.5rem;
}

.doctors-section h1 {
  margin-top: 2rem;
  text-align: center;
  font-family: 'Outfit';
  color: #097e38;
}

.searchBtn{
  border-width: 0px;
  background-color: #097e38;
  border-radius: 3px;
  color: white;
  width: 4.5rem;
  height: 2.1rem;
}


.search-bar {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 0.5rem;
  justify-content: left;
}

.row { 
 /* Make sure items are centered when fewer cards are present */
 justify-content: center;
}

.doctorscard {
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
  width: 16rem;
  height: 16rem;
  transition: background-color 0.3s ease;
}
.doctorscard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Initial transparent state */
  transition: background-color 0.3s ease; /* Smooth transition */
  z-index: 1; /* Below content */
}

.doctorscard:hover::before {
  background-color: rgba(0, 0, 0, 0.4); /* Darker overlay on hover */
}

.doctorscard:hover img {
  filter: brightness(0.7); /* Reduces brightness to darken the image */
}
.doctorscard:hover .btn {
  visibility: visible;
  z-index: 2;/* Reduces brightness to darken the image */
}

.doctorscard-text {
  border-radius: 0px 3px 3px 0px;
  padding: 5px 10px;
  text-align: center;
  color: white;
  background-color: #097e38;
  position: absolute;
  padding: -10px;
  bottom: 15px;
  left: 0px;

  h6{
    margin: 0; 
    font-family: 'Outfit';
  }
  p {
    margin: 0; 
    
    font-size: small; 
  }
}

.pagination {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc; /* Default dot color */
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #097e38; /* Active dot color */
}

i.bi {
  font-size: 1.5rem;
  cursor: pointer;
  color: #097e38;
}
</style>

