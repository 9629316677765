<template>
    <div class="services-container">
        <ul class="nav flex-column list-group list-group-flush">
        <h5>About Our MJSH</h5>
            <li class="nav-item list-group-item">
            <a 
                class="nav-link" 
                :class="{ active: isActive('/about-us/history') }" 
                href="/about-us/history"
            >History</a>
            </li>
            <li class="nav-item list-group-item">
            <a 
                class="nav-link" 
                :class="{ active: isActive('/about-us/mission-vision') } " style="text-align: center;" 
                href="/about-us/mission-vision"
            >Our Mission and Vision</a>
            </li>
        </ul>
        <div class="content">
            <div>
              <h4>History</h4>
              <br>
              <p>
                The Manuel J. Santos Hospital was founded in 1933 and has been serving the community for over 90 years. It was founded by the late Dr. Manuel J. Santos and his wife, the late Amparo Lopez. MJSH started as a 12-bed hospital and has continually expanded and upgraded its service capabilities, serving not just Butuan but the whole of Agusan, thereby establishing itself as the premier hospital on Northeastern Mindanao.<br><br>
                The hospital now operates under Metro Pacific Hospital Group, the largest private hospital group in the Philippines. MJSH operations are service-oriented and has strong collegial relationship among health workers to facilitate quality patient care. The hospital constantly pursues innovation and development of our facilities, equipment and techniques to achieve higher satisfaction of our clientele.<br><br>
                Currently, MJSH is a 100-bed, Level 2 healthcare facility and operates a free-standing Renal Care Center right across the street at the back of the hospital.<br><br>
                MJSH has continue to expand through its acquisition of prince hotel which will converted as a new (MAB) Medical Arts Building, and the creation of its new (PCC) Primary Care Center in the municipality of Nasipit.<br><br>
                The hospital attained progress through increased reliance and confidence in our medical staff, employees and facilities. Manuel J. Santos Hospital will continue to serve the region and will remain true to its commitment in serving the community.<br><br>
              </p>
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                    <div class="card ">
                      <img src="/assets/images/aboutUs/mjsantos-image-1.png" class="card-img-top" alt="...">
                      <div class="card-body">
                        <p class="card-text">1933</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                    <div class="card ">
                      <img src="/assets/images/aboutUs/mjsantos-image-2.png" class="card-img-top" alt="...">
                      <div class="card-body">
                        <p class="card-text">1953</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                    <div class="card ">
                      <img src="/assets/images/aboutUs/mjsantos-image-3.png" class="card-img-top" alt="...">
                      <div class="card-body">
                        <p class="card-text">1965</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                    <div class="card ">
                      <img src="/assets/images/aboutUs/mjsantos-image-4.png" class="card-img-top" alt="...">
                      <div class="card-body">
                        <p class="card-text">1995</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                    <div class="card ">
                      <img src="/assets/images/aboutUs/mjsantos-image-5.png" class="card-img-top" alt="...">
                      <div class="card-body">
                        <p class="card-text">2002</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  export default {   
    methods: {
      isActive(path) {
        return window.location.pathname === path;
      },
    },
  }
  </script>
  
  
  <style scoped lang="scss">
  .content{
    align-content: center;
    width: 55rem;
    margin: 1.5rem;
    margin-left: 3rem;
    margin-right: 3rem;
    h4{

    }
    h5{
      font-family: "University-roman-regular" ;
    }
    .container{
      img{
        width: 10rem;
        height: 6rem;
      }
      .card{
        text-align: center;
        width: 10rem;
      }
    }
    .servicescard-text{
      border-radius: 0px 3px 3px 0px;
      padding: 5px 10px;
      text-align: center;
      color: white;
      background-color: #097e38;
      position: absolute;
      padding: -10px;
      bottom: 15px;
      left: 0px;
      h6{
        margin: 0; 
        font-family: 'Outfit';
      }
    }
  }
  .services-container {
    font-family: 'montserrat';
    display: flex;
    margin-top: 8rem;
  }
  .nav {
    align-items: center;
    width: 15rem;
    h5{
      font-weight: 600;
      color: #097e38;
    }
  }
  .nav-link {
    color: black;
  }
  .nav-link:hover,
  .nav-link.active {
    color: #097e38 !important;
    font-weight: 600;
  }
  </style>
  