import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faHandPointRight, faMagnifyingGlass, } from '@fortawesome/free-solid-svg-icons';    
import 'bootstrap-icons/font/bootstrap-icons.css';

library.add(faHandPointRight, faMagnifyingGlass);

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
