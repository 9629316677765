<template>
  <div class="services-container">
      <ul class="nav flex-column list-group list-group-flush">
      <h5>Our Hospital Services</h5>
      <li class="nav-item list-group-item">
        <a 
          class="nav-link" 
          :class="{ active: isActive('/services/medical-services') }" 
          href="/services/medical-services"
        >Medical Services</a>
      </li>
      <li class="nav-item list-group-item">
        <a 
          class="nav-link" 
          :class="{ active: isActive('/services/nursing-services') }" 
          href="/services/nursing-services"
        >Nursing Services</a>
      </li>
      <li class="nav-item list-group-item">
        <a 
          class="nav-link" 
          :class="{ active: isActive('/services/ancillary-services') }" 
          href="/services/ancillary-services"
        >Ancillary Services</a>
      </li>
      <li class="nav-item list-group-item">
        <a 
          class="nav-link" 
          :class="{ active: isActive('/services/pharmacy-services') }" 
          href="/services/pharmacy-services"
        >Pharmacy Services</a>
      </li>
    </ul>
    <div class="service-content">
      <div v-for="(specialty, specialtyName) in organizedServices" :key="specialty.id">
        <h5>{{ specialtyName }}</h5>
        <ul>
          <li v-for="subspecialty in specialty.subspecialties" :key="subspecialty">{{ subspecialty }}</li>
        </ul>
      </div>
    </div>
</div>
</template>
<script>
export default {
  data() {
    return {
        organizedServices:{
      "Internal Medicine": {
        id: 1,
        subspecialties: [
          "Allergology", "Cardiology", "Dermatology", "Endocrinology Diabetes",
          "Gastroenterology", "General Medicine", "Hematology", "Infectious Diseases",
          "Intensive Care", "Medical Oncology", "Nephrology", "Neurology",
          "Psychiatry", "Pulmonary"
        ]
      },
      "Pediatrics": {
        id: 2,
        subspecialties: [
          "Adolescent Medicine", "Allergy & Immunology", "Cardiology", 
          "Clinical Toxicology", "Developmental & Behavioral", "Endocrinology",
          "Gastroenterology", "Hematology Infectious Diseases", "Intensive Care",
          "Neonatology", "Nephrology", "Neurology", "Oncology", 
          "Pulmonary Section", "Rheumatology"
        ]
      },
      "Surgery": {
        id: 3,
        subspecialties: [
          "Endoscopic Surgery", "General Surgery", "Minimally Invasive Surgery",
          "Neurosurgery", "Ophthalmology", "Otorhinolaryngology", "Pediatrics Surgery",
          "Plastic & Reconstructive Surgery", "Thoracic & Cardiovascular Section",
          "Urology", "Vascular & Transplant Urology"
        ]
      },
      "Obstetrics & Gynecology": {
        id: 4,
        subspecialties: [
          "General Obstetrics", "Gynecologic Oncology", "Hysteroscopy",
          "Laparoscopy", "Menopause", "Ob-Gyne Ultrasonology", "Perinatology",
          "Reproductive Endocrinology & Infertility", "Trophoblastic Diseases",
          "Uro-Gyne"
        ]
      },
      "Orthopedics": {
        id: 5,
        subspecialties: [
          "Arthroplasty & Joint Reconstruction", "Arthroscopy & Sports Medicine",
          "Foot & Ankle", "General Arthroscopy", "Hand & Microvascular",
          "Orthopedic Trauma", "Pediatrics & Limb Deformity", "Spine", "Tumor"
        ]
      },
      "Anesthesia": {
        id: 6,
        subspecialties: [
          "Cardiovascular", "Intensive Care", "Obstetrics", "Palliative Care",
          "Pediatrics", "Renal Transplantation", "Thoracic"
        ]
      },
      "Physical Therapy & Rehabilitation Medicine": {
        id: 7,
        subspecialties: [
          "Occupational Therapy", "Physical Therapy", "Special Education",
          "Speech Therapy", "Ultrasound Diagnostic & Therapeutics Neuromuscular System"
        ]
      },
      "Pathology & Laboratory Medicine": {
        id: 8,
        subspecialties: [
          "Anatomic Pathology", "Blood Banking & Transfusion Medicine", 
          "Clinical Pathology", "Screen Drug Testing Laboratory"
        ]
      },
      "Radiology": {
        id: 9,
        subspecialties: [
          "MRI", "CT Scan", "Mammogram", "Sonomammogram", "2d Echo", 
          "C-arm", "General X-ray", "General Ultrasound", "OB Ultrasound Procedures"
        ]
      },
      "Dental Medicine": {
        id: 10,
        subspecialties: ["Dental Medicine"]
      }
    },
    }
  },   
  methods: {
    isActive(path) {
      return window.location.pathname === path;
    },
  },
}

</script>

<style scoped lang="scss">
.service-content{
  padding-left: 2rem; 
  width: 80%;
  margin: 0; 
  h5{
    text-align: left;
    margin-top: 2rem;
    font-weight: 700;
  }
  ul{
    margin-bottom: 1.5rem;
    list-style-type: none;
    flex-wrap: wrap;
    display: flex;
    li{
      font-size: 15px;
      width: 18rem;
      margin-right: 1rem;
    }
  }
}
.services-container {
  font-family: 'montserrat';

  display: flex;
  margin-top: 8rem;
}
.nav {
  align-items: center;
  width: 15rem;
  h5{
    font-weight: 600;
    color: #097e38;
  }
}
.nav-link {
  color: black;
}
.nav-link:hover,
.nav-link.active {
  color: #097e38 !important;
  font-weight: 600;
}
</style>
