<template>
    <div class="services-container">
        <ul class="nav flex-column list-group list-group-flush">
        <h5>About Our MJSH</h5>
            <li class="nav-item list-group-item">
            <a 
                class="nav-link" 
                :class="{ active: isActive('/about-us/history') }" 
                href="/about-us/history"
            >History</a>
            </li>
            <li class="nav-item list-group-item">
            <a 
                class="nav-link" 
                :class="{ active: isActive('/about-us/mission-vision') } " style="text-align: center;" 
                href="/about-us/mission-vision"
            >Our Mission and Vision</a>
            </li>
        </ul>
        <div class="content">
            <div class="coreValues">
              <div class="row justify-content-center">
                <div class="col-md-6">
                    <h5>Our Vision</h5>
                    <img src="/assets/images/homepage/light-bulb.png" alt="Light Bulb">
                    <p>We are the leading and most valued integrated healthcare provider in Caraga, delivering high-quality, sustainable, compassionate, client-centered care, and is recognized as one of the country’s most trusted healthcare provider.</p>
                </div>
                <div class="col-md-6">
                    <h5>Our Mission</h5>
                    <img src="/assets/images/aboutUs/objective.png" alt="target">
                    <p>We deliver integrated quality healthcare services that are accessible and sustainable.
                    We contribute to nation-building by enabling a healthier Philippines.
                    We provide excellent and holistic care with compassion.
                    We help develop competent medical and clinical professionals.<br><br>
                    </p>
                </div>
                <div>
                  <h5>Our Core Values</h5>
                  <img src="/assets/images/homepage/value.png">
                  <div class="justify-content-center" style="display: flex;"><h6 class="coreText">Living</h6><h6 class="coreText">Our</h6> <h6 class="coreText">Values</h6>  <h6 class="coreText">Everyday</h6></div>  
                </div>
                <div class="mjsh-coreValues justify-content-center" style="display: flex;">
                  <h5>"We value LIFE, We </h5>  <h6 class="coreValuesText">LOVE</h6><h5>to SERVE"</h5> 
                </div>
              </div>
          </div>
          <div class="values-container">
              <div class="value">
                  <h3>We Value</h3>
                  <p><span class="acronym">L</span> ove for God</p>
                  <p><span class="acronym">I</span> ntegrity & Honesty</p>
                  <p><span class="acronym">F</span> airness to patients</p>
                  <p><span class="acronym">E</span> mpower the community</p>
              </div>
              <div class="value">
                  <h3>We LOVE</h3>
                  <p><span class="acronym">T</span> eamwork</p>
                  <p><span class="acronym">O</span> ptimism and precision</p>
                  <p><span class="acronym">S</span> ervice with Compassion and Care</p>
                  <p><span class="acronym">E</span> xcellence in work</p>
                  <p><span class="acronym">R</span> espect for each other</p>
                  <p><span class="acronym">V</span> alue accountability and pride in work</p>
                  <p><span class="acronym">E</span> agerness to learn and innovate</p>
              </div>
          </div>
        </div>
    </div>
  </template>
  <script>
  export default {   
    methods: {
      isActive(path) {
        return window.location.pathname === path;
      },
    },
  }
  </script>
  
  
  <style scoped lang="scss">
  .values-container {
    margin-left: 14rem;
    display: flex;
    justify-content: center;
    font-family: 'Outfit';

  }

  .value{
    width: 21.2rem;
  }

  .value p {
      font-size: 1.2rem;
  }

  .acronym {
      color: #097e38;
      font-weight: bold;
      font-size: 2.1rem;
  }

  .coreValues{
  margin-bottom: 1rem;
  padding: none;
  text-align: center;
  .mjsh-coreValues{
    h5{
      font-family: 'Outfit';
      color: black;
      font-size: 1.5rem;
      font-weight: 200;
    }
    .coreValuesText{
      margin-top: 1.5rem;
      margin-right: 10px;
      margin-left: 10px;
      font-family: 'Outfit';
      font-size: 2rem;
      color: #097e38;
    }
  }
  .coreText{
    margin-right: 10px;
    font-family: 'Outfit';
    font-size: 2rem;
    font-weight: 200;
    &::first-letter{
      font-weight: 600;
      font-size: 2.5rem;
      color: #097e38;
      text-decoration: underline;
    }
  }
  p{
    font-size: large;
    font-weight: 400;
    font-family: 'Montserrat';
    
  }
  img{
    width: 3rem;
    margin-bottom: 1rem;
  }
  h5{
    text-align: center;
    font-family: 'Outfit';
    font-weight: 600;
    font-size: 30px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #097e38;
  }
}
  .content{
    align-content: center;
    width: 55rem;
    margin: 1.5rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .services-container {
    font-family: 'montserrat';
    display: flex;
    margin-top: 8rem;
  }
  .nav {
    align-items: center;
    width: 15rem;
    h5{
      font-weight: 600;
      color: #097e38;
    }
  }
  .nav-link {
    color: black;
  }
  .nav-link:hover,
  .nav-link.active {
    color: #097e38 !important;
    font-weight: 600;
  }
  </style>
  