<template>
    <div id="packages" class="packages-section ">
    <h3>Maternity Packages</h3>
    <div class="row justify-content-center mt-5 gap-5 ">
        <div class="col-12 col-md-4 packages-card shadow-lg">
        <h4>Plan A (Ward)</h4>
        <li>
        Normal Spontaneous Vaginal Delivery
        </li>
        <h5></h5>
        <li>
        Caesarian Section
        </li>
        <h5></h5>
        </div>
        <div class="col-md-4 col-12 packages-card shadow-lg">
        <h4>Plan B (Private Room)</h4>
        <li>
        Normal Spontaneous Vaginal Delivery
        </li>
        <h5></h5>
        <li>
        Caesarian Section
        </li>
        <h5></h5>
        </div>
    </div>

    <h3>Primary Care Packages</h3>
    <div class="row justify-content-center mt-5 gap-5">
        <div class="col-12 col-md-4 packages-card shadow-lg">
          <h4>Basic Kidney Care Test</h4>
          <p>(MALE & FEMALE)</p>
          <h5></h5>
        </div>
        <div class="col-12 col-md-4 packages-card shadow-lg">
          <h4>Basic Heart Care Test</h4>
          <p>(MALE & FEMALE)</p>
          <h5></h5>
        </div>
        <div class="col-12 col-md-4 packages-card shadow-lg">
          <h4>Basic Pregnancy Care Test</h4>
          <p>(FEMALE)</p>
          <h5></h5>
        </div>
        <div class="col-12 col-md-4 packages-card shadow-lg">
          <h4>Basic Liver Care Test</h4>
          <p>(MALE & FEMALE)</p>
          <h5></h5>
        </div>
        <div class="col-12 col-md-4 packages-card shadow-lg">
          <h4>Basic Liver Care Test</h4>
          <p>(MALE & FEMALE)</p>
          <h5></h5>
        </div>
        <div class="col-12 col-md-4 packages-card shadow-lg">
          <h4>Cardio Panel Test</h4>
          <p>(MALE & FEMALE)</p>
          <h5></h5>
        </div>
        <div class="col-12 col-md-4 packages-card shadow-lg">
          <h4>Pre-Employment Package</h4>
          <p>(MALE & FEMALE)</p>
          <h5></h5>
        </div>
        <div class="col-12 col-md-4 packages-card shadow-lg">
          <h4>Basic 5 Package</h4>
          <p>(MALE & FEMALE)</p>
          <h5></h5>
        </div>
        <div class="col-12 col-md-4 packages-card shadow-lg">
          <h4>Basic Starter Care Test</h4>
          <p>(MALE & FEMALE)</p>
          <h5></h5>
        </div>
        <div class="col-12 col-md-4 packages-card shadow-lg">
          <h4>Basic DM Care Test</h4>
          <p>(MALE & FEMALE)</p>
          <h5></h5>
        </div>
    </div>

    <h3>Executive Packages</h3>
    <div class="row justify-content-center mt-5 gap-5">
        <div class="col-12 col-md-4 packages-card shadow-lg">
        <h4>Executive Panel A</h4>
        <li>
          (FEMALE)
        </li>
        <h5></h5>
        <li>
        (MALE)
        </li>
        <h5></h5>
        </div>
        <div class="col-12 col-md-4 packages-card shadow-lg">
        <h4>Executive Panel B</h4>
        <li>
        (FEMALE)
        </li>
        <h5></h5>
        <li>
        (MALE)
        </li>
        <h5></h5>
        </div>
    </div>
    </div>
   
</template>

<style scoped lang="scss">
.packages-section {
  background-color: white;
  padding-top: 5rem;
  padding-bottom: 100px;
  font-family: 'Outfit';
  h3 {
    color: #236843;
    padding-top: 50px;
    text-align: center;
  }
}
.packages-card {
  background-color: white;
  border-radius: 30px;
  padding: 30px;
  h5 {
    font-weight: 600;
    color: #236843;
    padding-top: 0; /* Override padding or other inherited styles */
    text-align: center; /* Change alignment if needed */
  }
}

@media (max-width: 740px) {
    .packages-card {
        margin-bottom: 1.5rem;
        padding: 1.5rem;
    }
    .row{
      margin-left: 2rem;
      margin-right: 2rem;
    }
}
</style>